
import { router } from '@inertiajs/vue3'
import { toast as toaster } from 'vue3-toastify'

export default {
  install(app) {
    app.config.globalProperties.goTo = function (url, options = {}) {
      router.visit(url, options)
    }

    app.config.globalProperties.logout = function () {
      router.post(route('logout'))
    }

    app.config.globalProperties.toast = function (message, options = {}) {
      let toastOptions = {
        hideProgressBar: true,
        theme: 'auto',
        dangerouslyHTMLString: true,
      }
      let allOptions =Object.assign(options, toastOptions)
      toaster(message, allOptions)
    }

    app.config.globalProperties.copyToClipboard = function (text) {
      navigator.clipboard.writeText(text).then(() => {
        app.config.globalProperties.toast('Copied to clipboard', {
          position: 'top-center'
        })
      })
    }
  }
}
