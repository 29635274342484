import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { VLazy } from 'vuetify/components/VLazy'
import 'vuetify/styles'

const vuetify = createVuetify({
  components,
  directives,
  aliases: {
    VLazyAgent: VLazy,
  },
  defaults: {
    VLazyAgent: {
      options: { threshold: 1.0 },
      minHeight: 50,
      transition: 'expand-transition',
    }
  }
})

export default vuetify
