export default {
  mounted(el) {

    const copyIcon = document.createElement('span')
    copyIcon.innerHTML = '📋'
    copyIcon.style.cursor = 'pointer'
    copyIcon.style.marginLeft = '8px'
    copyIcon.title = 'Copy to clipboard'
    copyIcon.style.filter = 'none'
    copyIcon.role = 'button'

    el.appendChild(copyIcon)

    copyIcon.addEventListener('click', () => {

      const tempElement = document.createElement('div')
      tempElement.innerHTML = el.innerHTML.replace(copyIcon.outerHTML, '')
      const textToCopy = tempElement.innerHTML

      navigator.clipboard.writeText(textToCopy)
        .then(() => {

          const originalInnerHTML = copyIcon.innerHTML
          copyIcon.style.color = 'green'
          copyIcon.innerHTML = 'Copied!'
          setTimeout(() => {
            copyIcon.innerHTML = originalInnerHTML
          }, 2000)
        })
        .catch(err => {
          console.error('Failed to copy text: ', err)
        })
    })
  }
}
