export default {
  computed: {
    $user () {
      return this.$page.props.auth.user
    },
    $breakpoint () {
      return this.$vuetify.display
    },
    $can () {
      return this.$page.props.can
    },
    systemSettings () {
      return this.$page.props['system-settings']
    }
  },
}
