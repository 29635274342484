<template>
  <span small link text style="cursor: pointer;">
    <span class="underline hover:no-underline">{{ dateTimeTitle }}</span>
    <v-tooltip activator="parent" location="top" color="primary">
      <span>{{ dateTimeString }}</span>
    </v-tooltip>
  </span>
</template>

<script setup>
  import moment from 'moment'
  import { computed } from 'vue'

  defineOptions({
    name: 'DateTimeTooltip',
  })

  const props = defineProps({
    dateString: {
      type: String,
      required: true,
    },
    dateTitle: {
      type: String,
    }
  })

  const dateTimeTitle = computed(() => {
    return props.dateTitle ?
      props.dateTitle :
      moment(props.dateString).fromNow()
  })

  const dateTimeString = computed(() => {
    return moment(props.dateString).format('lll')
  })

</script>
