import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp, Head } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import AppMixin from '@/Mixins/app'
import DateTooltip from '@/Components/components/DateTooltip.vue'
import VFlex from '@/Components/Layouts/VFlex.vue'
import MkBreadcrumbs from '@/Components/components/mk-breadcrumbs.vue'
import myPlugin from '@/Plugins/myPlugin'

import '@mdi/font/css/materialdesignicons.css'
// import VueTypedJs from 'vue3-typed-js'
import InertiaLink from '@/Components/components/Inertia-link.vue'

import vCopy from '@/Directives/vCopy'

import vuetify from './Plugins/vuetify'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel'

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(vuetify)
            .use(myPlugin)
            .mixin(AppMixin)
            .component('Head', Head)
            .component('VFlex', VFlex)
            .component('InertiaLink', InertiaLink)
            .component('DateTooltip', DateTooltip)
            .component('MkBreadcrumbs', MkBreadcrumbs)
            // .component('VueTypedJs', VueTypedJs)
            .directive('copy', vCopy)
            .mount(el)

        window.app = app

        return app
    },
    progress: {
        color: '#4B5563',
    },
})
